import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://media.giphy.com/media/gdlhgjLfYDr3xaaCLV/giphy.gif" className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
